<template>
  <div class="webcams py-6 lg:pb-8">
    <div class="heading-5">{{ translations['MENU.WEBCAMS'] }}</div>
    <div class="flex flex-wrap justify-center -mx-gap -mb-8">
      <div
        v-for="item in webcams"
        :key="item.webcam"
        class="w-full px-gap mb-8 md:w-1/2"
        :class="webcams.length > 2 ? 'lg:w-4/12' : 'lg:w-6/12'"
      >
        <div
          v-if="getWebcamQueryData(item.webcam).id"
          :id="'roundshot-thumbnail-' + getWebcamQueryData(item.webcam).id"
          class="overflow-hidden"
        ></div>
        <img
          v-else
          :src="getWebcamQueryData(item.webcam).url"
          class="webcam-image w-full object-cover"
          :alt="`Webcam ${item.title}`"
          loading="lazy"
        />
        <a :href="getWebcamQueryData(item.webcam).url" class="arrow-link" target="_blank">{{ item.title }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Webcams',
  props: ['translations', 'webcams'],
  mounted() {
    this.webcams.forEach(item => {
      const webcamData = this.getWebcamQueryData(item.webcam);
      if (webcamData.id) {
        window.roundshot.animate({
          id: webcamData.id,
          picture: `https://backend.roundshot.com/cams/${webcamData.id}/thumbnail`,
          link: webcamData.url,
          angle: 360,
          width: 922,
          height: 188,
          bounce: false,
          open_in_new_tab: true,
          speed: 20
        });
      }
    });
  },
  data: () => ({
    DIRECT_ACCESS: {
      WEBCAMS: 'WEBCAMS'
    },
    webcamQueryData: [
      {
        slug: 'torrentRoundshot',
        id: 'd3048fd4c1f407c9bd9b3d54b7b3fad3',
        url: 'https://torrent.roundshot.com/'
      },
      {
        slug: 'gemmiRoundshot',
        id: '063853a9a6eabec67f9b4c974637af40',
        url: 'https://gemmi.roundshot.com/'
      },
      {
        slug: 'albinenRoundshot',
        id: 'c80edea35dab22ccd1c942881e364d0e',
        url: 'https://albinen.roundshot.com/'
      },
      {
        slug: 'varenRoundshot',
        id: 'a6b85da4b8451daf391680e10a06dc68',
        url: 'https://varen.roundshot.com/'
      },
      {
        slug: 'thermeRoundshot',
        id: 'ca47a7f49b58ad346731604cf81cbb4c',
        url: 'https://leukerbad.roundshot.com/thermalbad/'
      },
      {
        slug: 'inden1',
        url: 'https://webcam.inden.ch/webcam/big.jpg'
      },
      {
        slug: 'inden2',
        url: 'https://webcam.inden.ch/webcam2/big.jpg'
      },
      {
        slug: 'bodmerstuebli',
        url: 'https://mywebcam.ch/bodmenstuebli/bodmenstuebli.jpg'
      },
      {
        slug: 'sportarena_snowpark',
        id: 'db5fe5317bbbe8cb776ca5432814f715',
        url: 'https://leukerbad.roundshot.com/erli/'
      }
    ]
  }),
  methods: {
    getWebcamQueryData(slug) {
      return this.webcamQueryData.find(webcam => webcam.slug === slug);
    }
  }
};
</script>
