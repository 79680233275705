<template>
  <div>
    <nav v-if="data" class="desktop w-full hidden lg:block" :class="{ hide: navHidden }">
      <div class="nav-row-container" :class="[smallNav || !headerImagePresent ? 'wider' : 'inlay']">
        <div class="inlay">
          <div
              class="bg-primary-white flex relative nav-row-container-inner"
              :class="{ wider: smallNav || !headerImagePresent }"
              ref="navRowContainerInner"
          >
            <div class="flex-1 px-gap-lg text-left pr-0">
              <a :href="homeUrl" :aria-label="translations['ARIA.HOME']">
                <img
                    :class="{ smaller: smallNav, small: !directAccessHidden && smallNav }"
                    :src="`/assets/images/logos/primary/logo-with-claim-${data.entry.language}.svg`"
                    class="nav-logo h-32 w-32"
                    alt="Logo Leukerbad"
                />
              </a>
            </div>
            <div class="px-gap-lg">
              <div class="nav-info-row" :class="{ hide: directAccessHidden }">
                <div class="flex flex-wrap -mr-gap-lg place-content-between border-b border-tertiary-gray pb-1">
                  <div class="direct-access-link-container space-x-2">
                    <div
                        class="direct-access-link"
                        :class="{ active: isDirectAccess(DIRECT_ACCESS.MOUNTAIN_RAILWAYS) && !triggeredByStickyDirectAccess }"
                        @click="toggleDirectAccess(DIRECT_ACCESS.MOUNTAIN_RAILWAYS)"
                    >
                      <div class="link-info-bubble">{{ translations['MENU.MOUNTAIN_RAILWAYS'] }}</div>
                      <i class="icon-cablecar"></i>
                    </div>
                    <div
                        class="direct-access-link"
                        :class="{ active: isDirectAccess(DIRECT_ACCESS.WEBCAMS) && !triggeredByStickyDirectAccess }"
                        @click="toggleDirectAccess(DIRECT_ACCESS.WEBCAMS)"
                    >
                      <div class="link-info-bubble">{{ translations['MENU.WEBCAMS'] }}</div>
                      <i class="icon-webcam"></i>
                    </div>
                    <div
                        class="direct-access-link"
                        :class="{ active: isDirectAccess(DIRECT_ACCESS.INFO_CORNER) && !triggeredByStickyDirectAccess }"
                        @click="toggleDirectAccess(DIRECT_ACCESS.INFO_CORNER)"
                    >
                      <div class="link-info-bubble">{{ translations['MENU.INFO'] }}</div>
                      <i class="icon-weather"></i>
                    </div>
                    <div
                        class="direct-access-link"
                        :class="{ active: isDirectAccess(DIRECT_ACCESS.MAPS) && !triggeredByStickyDirectAccess }"
                        @click="toggleDirectAccess(DIRECT_ACCESS.MAPS)"
                    >
                      <div class="link-info-bubble">{{ translations['MENU.MAPS'] }}</div>
                      <i class="icon-maps"></i>
                    </div>
                    <div
                        class="direct-access-link relative"
                        :class="{ active: isDirectAccess(DIRECT_ACCESS.NEWS) && !triggeredByStickyDirectAccess }"
                        @click="toggleDirectAccess(DIRECT_ACCESS.NEWS)"
                    >
                      <div class="link-info-bubble">News</div>
                      <i class="icon-news"> </i>
                      <div class="counter" :class="filteredIssues.length > 0 ? '' : 'hidden'">
                        {{ filteredIssues.length }}
                      </div>
                    </div>
                    <div class="direct-access-link relative">
                      <div class="link-info-bubble">{{ translations['ARIA.SEARCH'] }}</div>
                      <a :href="data.search.url" class="search-icon" :aria-label="translations['ARIA.SEARCH']"></a>
                    </div>
                  </div>
                  <div class="info-tourist-office" v-html="data.navigation.infoTouristOfficeDesktopMenu"></div>
                  <div class="pr-gap-lg flex items-center">
                    <LanguageSwitcher :language-switcher="languageSwitcher" :translations="translations"/>
                  </div>
                </div>
              </div>
              <div class="nav-link-row flex -mx-gap place-content-between py-8" :class="{ smaller: smallNav }">
                <div class="px-gap">
                  <ul class="navigation-links main space-x-8">
                    <li v-for="item in data.navigation.items" :key="item.mainPage[0].id"
                        :class="{ active: isMainNavItemActive(item) }">
                      <a :href="item.mainPage[0].url" class="mainheader">{{ item.mainPage[0].title }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            v-if="currentMainNavItem"
            class="sub-links nav-row-container"
            :class="{ wider: smallNav || !headerImagePresent }"
            ref="subLinksBar"
        >
          <div class="inlay">
            <div class="bg-tertiary-gray-lighten-2 sub-links nav-row-container-inner"
                 :class="{ wider: smallNav || !headerImagePresent }">
              <div class="px-gap-lg">
                <ul class="navigation-links space-x-10 flex justify-center">
                  <li v-if="data.entry.sectionHandle !== 'shop'" :class="{ active: isHubPage }">
                    <a :href="currentMainNavItem.mainPage[0].url" class="subheader">{{ translations.MENU_OVERVIEW }}</a>
                  </li>
                  <li v-for="item in currentMainNavItem.subpages" :key="item.id" :data-entry-id="item.id"
                      :class="{ active: isSubNavItemActive(item) || item.id === initialShopTabId }">
                    <a :href="item.url" class="subheader">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="inlay" v-if="activeDirectAccess">
      <div
          class="direct-access-container desktop"
          :style="{ maxHeight: containerHeight, width: triggeredByStickyDirectAccess ? directAccessContainerWidth() + 'px': 'auto' }"
          :class="{ 'with-shadow': headerImagePresent && !smallNav, inlay: smallNav || !headerImagePresent, 'free-floating': triggeredByStickyDirectAccess }"
      >
        <div class="px-24">
          <div class="text-right pt-8">
            <i class="icon-close-small" @click="toggleDirectAccess(activeDirectAccess)"></i>
          </div>
          <MountainRailways
              v-if="isDirectAccess(DIRECT_ACCESS.MOUNTAIN_RAILWAYS)"
              :railways="railways"
              :translations="translations"
              :railwaysLinkUrl="data.railwaysLinkUrl"
              :railwaysLastUpdated="data.railwaysLastUpdated"
          />
          <Webcams v-if="isDirectAccess(DIRECT_ACCESS.WEBCAMS)" :webcams="data.navigation.webcams"
                   :translations="translations"/>
          <InfoCorner
              v-if="isDirectAccess(DIRECT_ACCESS.INFO_CORNER)"
              :forecasts="data.navigation.forecasts"
              :snowData="data.navigation.snowData"
              :language="data.entry.language"
              :translations="translations"
          />
          <Maps
              v-if="isDirectAccess(DIRECT_ACCESS.MAPS)"
              :maps="data.navigation.maps"
              :mapLinks="data.navigation.mapLinks"
              :mapLinksTitle="data.navigation.mapLinksTitle"
              :winterMapUrl="data.navigation.winterMapUrl"
              :summerMapUrl="data.navigation.summerMapUrl"
              :language="data.entry.language"
              :translations="translations"
          />
          <News
              v-if="isDirectAccess(DIRECT_ACCESS.NEWS)"
              :translations="translations"
              :issuesTitle="data.navigation.issuesTitle"
              :issues="filteredIssues"
              :guestInfosTitle="data.navigation.guestInfosTitle"
              :guestInfo="data.navigation.guestInfo"
              :notificationLinks="data.navigation.notificationLinks"
              :notificationsLinksTitle="data.navigation.notificationsLinksTitle"
          />
        </div>
      </div>
    </div>
    <div class="sticky-direct-access-link-container hidden lg:flex" :class="{ hide: !directAccessHidden }">
      <div
          class="sticky-direct-access-link"
          :class="{ active: isDirectAccess(DIRECT_ACCESS.MOUNTAIN_RAILWAYS) && triggeredByStickyDirectAccess }"
          @click="toggleDirectAccess(DIRECT_ACCESS.MOUNTAIN_RAILWAYS, true)"
      >
        <div class="link-info-bubble-left">{{ translations['MENU.MOUNTAIN_RAILWAYS'] }}</div>
        <i class="icon-cablecar"></i>
      </div>
      <div
          class="sticky-direct-access-link"
          :class="{ active: isDirectAccess(DIRECT_ACCESS.WEBCAMS) && triggeredByStickyDirectAccess }"
          @click="toggleDirectAccess(DIRECT_ACCESS.WEBCAMS, true)"
      >
        <div class="link-info-bubble-left">{{ translations['MENU.WEBCAMS'] }}</div>
        <i class="icon-webcam"></i>
      </div>
      <div
          class="sticky-direct-access-link"
          :class="{ active: isDirectAccess(DIRECT_ACCESS.INFO_CORNER) && triggeredByStickyDirectAccess }"
          @click="toggleDirectAccess(DIRECT_ACCESS.INFO_CORNER, true)"
      >
        <div class="link-info-bubble-left">{{ translations['MENU.INFO'] }}</div>
        <i class="icon-weather"></i>
      </div>
      <div
          class="sticky-direct-access-link"
          :class="{ active: isDirectAccess(DIRECT_ACCESS.MAPS) && triggeredByStickyDirectAccess }"
          @click="toggleDirectAccess(DIRECT_ACCESS.MAPS, true)"
      >
        <div class="link-info-bubble-left">{{ translations['MENU.MAPS'] }}</div>
        <i class="icon-maps"></i>
      </div>
      <div
          class="sticky-direct-access-link relative"
          :class="{ active: isDirectAccess(DIRECT_ACCESS.NEWS) && triggeredByStickyDirectAccess }"
          @click="toggleDirectAccess(DIRECT_ACCESS.NEWS, true)"
      >
        <div class="link-info-bubble-left">News</div>
        <i class="icon-news"> </i>
        <div class="counter" :class="filteredIssues.length > 0 ? '' : 'hidden'">
          {{ filteredIssues.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher';
import MountainRailways from '@/components/layout/direct-access/MountainRailways';
import Webcams from '@/components/layout/direct-access/Webcams';
import InfoCorner from '@/components/layout/direct-access/InfoCorner';
import Maps from '@/components/layout/direct-access/Maps';
import News from '@/components/layout/direct-access/News';
import infoSnowService from "../common/services/infoSnowService";

export default {
  name: 'Navigation',
  components: {MountainRailways, News, Maps, InfoCorner, Webcams, LanguageSwitcher},
  props: ['data', 'languageSwitcher', 'translations'],
  data: () => ({
    railways: [],
    currentScrollPosition: 0,
    isScrollingDown: false,
    DIRECT_ACCESS: {
      MOUNTAIN_RAILWAYS: 'MOUNTAIN_RAILWAYS',
      WEBCAMS: 'WEBCAMS',
      INFO_CORNER: 'INFO_CORNER',
      MAPS: 'MAPS',
      NEWS: 'NEWS'
    },
    activeDirectAccess: '',
    triggeredByStickyDirectAccess: false,
    containerHeight: 0,
    initialShopTabId: ''
  }),
  async created() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.currentMainNavItem?.mainPage[0].sectionHandle === 'shop') {
      window.addEventListener('hashchange', this.handleHashChange);
    }
    const railwaysResult = (await infoSnowService.getMountainRailwaysData(this.data.entry.language.slice(0, 2))).data;
    this.railways = infoSnowService.formatMountainRailways(this.data.navigation.railways, railwaysResult);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    if (this.currentMainNavItem?.mainPage[0].sectionHandle === 'shop') {
      window.removeEventListener('hashchange', this.handleHashChange);
    }
  },
  computed: {
    homeUrl() {
      return `/${this.data.entry.language === 'de-CH' ? '' : this.data.entry.language.substring(0, 2)}`;
    },
    currentMainNavItem() {
      return this.data.navigation.items.find(item => this.isMainNavItemActive(item));
    },
    isHubPage() {
      return this.currentMainNavItem.mainPage[0].id === this.data.entry.id && !(this.data.entry.sectionHandle === 'shop');
    },
    headerImagePresent() {
      return document.getElementsByClassName('header-image-overlay').length > 0;
    },
    smallNav() {
      return this.currentScrollPosition > 60;
    },
    directAccessHidden() {
      return (!this.activeDirectAccess || this.triggeredByStickyDirectAccess) && this.smallNav;
    },
    navHidden() {
      return this.isScrollingDown && (!this.activeDirectAccess || this.triggeredByStickyDirectAccess) && this.smallNav;
    },
    filteredIssues() {
      return this.data.navigation.issues ? this.data.navigation.issues.filter(issue => !issue.date || new Date(issue.date).toISOString() > new Date().toISOString()) : [];
    }
  },
  mounted() {
    this.directAccessContainerHeight();
    if (this.currentMainNavItem?.mainPage[0].sectionHandle === 'shop' && !window.location.hash) {
      let allSubpages = [];
      this.data.navigation.items.forEach(item => {
        allSubpages = allSubpages.concat(item.subpages);
      });
      this.initialShopTabId = allSubpages.find(subpage => subpage.sectionHandle === 'shopTopproducts').id;
    }
  },
  methods: {
    handleScroll() {
      this.isScrollingDown = window.scrollY > this.currentScrollPosition;
      this.currentScrollPosition = window.scrollY;
    },
    isMainNavItemActive(item) {
      return (
        this.data.entry.id === item.mainPage[0].id ||
        item.subpages.some(subpage => subpage.id === this.data.entry.id) ||
        (this.data.entry.parent && item.subpages.some(subpage => subpage.id === this.data.entry.parent.id))
      );
    },
    isSubNavItemActive(item) {
      const itemId = this.data.entry.sectionHandle === 'shop' ? this.getItemIdFromHash() : this.data.entry.id;
      return itemId === item.id || (this.data.entry.parent && this.data.entry.parent.id === item.id);
    },
    toggleDirectAccess(page, triggeredByStickyDirectAccess = false) {
      if (this.isDirectAccess(page)) {
        this.triggeredByStickyDirectAccess = false;
        document.getElementsByTagName('body')[0].classList.remove('freeze-y');
        this.activeDirectAccess = '';
      } else {
        this.triggeredByStickyDirectAccess = triggeredByStickyDirectAccess;
        this.directAccessContainerHeight();
        this.activeDirectAccess = page;
        document.getElementsByTagName('body')[0].classList.add('freeze-y');
      }
    },
    isDirectAccess(page) {
      return this.activeDirectAccess === page;
    },
    directAccessContainerHeight() {
      let iconBarHeight = this.$refs.navRowContainerInner.clientHeight;
      let subLinksBarHeight = this.$refs.subLinksBar != null ? this.$refs.subLinksBar.clientHeight : '0';
      this.containerHeight = 'calc(100vh - ' + subLinksBarHeight.toString() + 'px - ' + iconBarHeight.toString() + 'px - 32px)';
    },
    directAccessContainerWidth() {
      return this.$refs.navRowContainerInner.clientWidth;
    },
    handleHashChange() {
      document.querySelectorAll('[data-entry-id]').forEach(item => item.classList.remove('active'));
      document.querySelector('[data-entry-id="' + this.getItemIdFromHash() + '"]').classList.add('active');
    },
    getItemIdFromHash() {
      let allSubpages = [];
      this.data.navigation.items.forEach(item => {
        allSubpages = allSubpages.concat(item.subpages);
      });
      let hash = window.location.href.split("/").pop();
      switch (hash) {
        case 'pois':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopPois').id;
        case 'erlebnisse':
        case 'experiences':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopExperiences').id;
        case 'unterkuenfte':
        case 'accommodations':
        case 'hebergements':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopAccommodations').id;
        case 'veranstaltungen':
        case 'events':
        case 'manifestations':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopEvents').id;
        case 'tickets':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopTickets').id;
        case 'topprodukte':
        case 'topproduits':
        case 'topproducts':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopTopproducts').id;
        case 'souvenir':
          return allSubpages.find(subpage => subpage.sectionHandle === 'shopSouvenir').id;
        default:
          return this.data.entry.id;
      }
    }
  }
};
</script>
