<template>
  <div>
    <div class="language-wrapper pr-1 hidden lg:block" @click="toggleSwitcher()">
      <div class="relative cursor-pointer">
        <span class="text-sm">{{ translations['LANGUAGE'] }}</span>
        <nav class="language-list" :aria-label="translations['ARIA.SWITCH_LANGUAGE']" aria-role="navigation" v-if="isOpen">
          <ul class="list-none pl-0 divide-y divide-tertiary-gray">
            <li :class="{ 'is-active': item.current }" v-for="item in languageSwitcher" :key="item.language">
              <a :href="item.url" hreflang="{{item.language}}" lang="{{item.language}}">
                {{ item.title.split(' ')[0] }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="language-wrapper pr-1 lg:hidden" data-selector="language-selector">
      <nav :aria-label="translations['ARIA.SWITCH_LANGUAGE']" aria-role="navigation">
        <ul class="list-none pl-0">
          <li
            v-for="item in languageSwitcher"
            class="cursor-pointer py-3 border-b border-tertiary-gray"
            :class="{ 'font-bold': item.current }"
          >
            <a class="text-primary-black hover:text-primary-red" :href="item.url" hreflang="{{item.language}}" lang="{{item.language}}">
              {{ item.title.split(' ')[0] }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  props: ['languageSwitcher', 'translations'],
  data: () => ({
    isOpen: false
  }),
  methods: {
    toggleSwitcher: function() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>
