<template>
  <div class="maps py-6 lg:pt-8">
    <div class="heading-5">{{ translations['MENU.MAPS'] }}</div>
    <div class="flex flex-wrap justify-center -mx-gap">
      <div class="w-full lg:w-8/12 px-gap -mb-4">
        <div class="flex flex-wrap justify-center -mx-gap">
          <div v-for="map in maps" :key="map.id" class="w-full md:w-6/12 px-gap mb-4">
            <picture v-if="map.type === 'mapsBlock'">
              <!--[if IE 9]> <video style="display: none;><![endif]-->
              <source media="(max-width: 375px)" :data-srcset="`${map.image[0]['iPhone']} 1x, ${map.image[0]['iPhone_2x']} 2x`" />
              <source media="(max-width: 414px)" :data-srcset="`${map.image[0]['iPhonePlus']} 1x, ${map.image[0]['iPhonePlus_2x']} 2x`" />
              <source media="(max-width: 768px)" :data-srcset="`${map.image[0]['iPad']} 1x, ${map.image[0]['iPad_2x']} 2x`" />
              <source media="(max-width: 1024px)" :data-srcset="`${map.image[0]['iPadPro']} 1x, ${map.image[0]['iPadPro_2x']} 2x`" />
              <source media="(min-width: 1025px)" :data-srcset="`${map.image[0]['desktop']} 1x, ${map.image[0]['desktop_2x']} 2x`" />
              <!--[if IE 9]></video><![endif]-->
              <img :data-src="`${map.image[0]['iPhone']}`" :alt="map.title" data-sizes="auto" class="lazyload" />
            </picture>
            <a v-if="map.type === 'mapsBlock'" class="arrow-link" :href="map.image[0]['full']" target="_blank">{{ map.title }}</a>
            <div v-if="map.type === 'interactiveMap'" class="infosnow-map">
              <iframe :src="`${map.isWinter ? winterMapIFrameUrl : summerMapIFrameUrl}&fit=2&mini=1`"></iframe>
              <a :href="map.isWinter ? winterMapUrl : summerMapUrl" target="_blank"></a>
            </div>
            <a v-if="map.type === 'interactiveMap'" class="arrow-link" :href="map.isWinter ? winterMapUrl : summerMapUrl" target="_blank">{{ map.title }}</a>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-4/12 px-gap pt-4 md:pt-0">
        <div class="text-lg pb-4">{{ mapLinksTitle }}</div>
        <ul class="link-list">
          <li v-for="link in mapLinks" :key="link.url">
            <a :href="link.url[0].url">{{ link.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import imageService from '@/services/imageService';

export default {
  name: 'Maps',
  props: ['maps', 'mapLinks', 'mapLinksTitle', 'winterMapUrl', 'summerMapUrl', 'language', 'translations'],
  data: () => ({
    winterMapIFrameUrl: null,
    summerMapIFrameUrl: null
  }),
  mounted() {
    this.winterMapIFrameUrl = `https://infosnow.ch/~apgmontagne/?id=48&tab=map-wi&slopes=1&lang=${this.language.slice(0, 2)}`;
    this.summerMapIFrameUrl = `https://infosnow.ch/~apgmontagne/?id=48&tab=map-su&lang=${this.language.slice(0, 2)}`;
  },
  methods: {
    getTransformedSrcSet: images => imageService.getTransformedSrcSet(images)
  }
};
</script>
