<template>
  <div class="news py-6 lg:pb-8">
    <div class="heading-5">{{ translations['MENU.NEWS'] }}</div>
    <div class="flex flex-wrap -mx-gap">
      <div class="w-full xl:w-4/12 px-gap pb-6 xl:pb-0">
        <div class="text-lg pb-4">{{ issuesTitle }}</div>
        <ul class="notifications">
          <template v-if="issues.length > 0">
            <li v-for="item in issues" class="py-3" :key="item.title">
              <div class="text-primary-red font-normal">{{ item.title }}</div>
              <div v-html="item.text" class="text-sm"></div>
            </li>
          </template>
          <li class="py-3 text-success font-normal" v-else>{{ translations['MENU.NO_ISSUES'] }}</li>
        </ul>
      </div>
      <div class="w-full xl:w-4/12 px-gap pb-6 xl:pb-0">
        <div class="text-lg pb-4">{{ guestInfosTitle }}</div>
        <ul class="notifications">
          <template v-if="guestInfo.length > 0">
            <li v-for="item in guestInfo" :key="item.title" class="py-3">
              <div class="text-primary-red font-normal">{{ item.title }}</div>
              <div v-html="item.text" class="text-sm"></div>
            </li>
          </template>
          <li class="py-3" v-else>{{ translations['MENU.NO_GUESTINFO'] }}</li>
        </ul>
      </div>
      <div class="w-full xl:w-4/12 px-gap">
        <div class="text-lg pb-4">{{ notificationsLinksTitle }}</div>
        <ul class="link-list">
          <li v-for="link in notificationLinks" :key="link.url">
            <a :href="link.url[0].url">{{ link.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  props: ['translations', 'issuesTitle', 'issues', 'guestInfosTitle', 'guestInfo', 'notificationsLinksTitle', 'notificationLinks'],
  data: () => ({
    currentScrollPosition: false
  })
};
</script>
