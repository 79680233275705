<template>
  <div class="mountain-railways py-6 lg:pb-8">
    <div class="heading-5">{{ translations['MENU.MOUNTAIN_RAILWAYS'] }}</div>
    <div class="flex flex-wrap -mx-gap">
      <div v-for="railway in railways" :key="railway.id" class="facility px-gap w-full">
        <div class="lift items-center py-4">
          <div class="grid grid-cols-12 items-center">
            <div class="col-span-1 items-start">
              <div class="relative inline-flex w-10">
                <img :src="`/assets/images/apg_icons/${railway.type}.svg`" class="lg:w-8 lg:mr-2" aria-hidden="true"
                     alt=""/>
              </div>
            </div>
            <div class="col-span-11 flex flex-col lg:grid lg:grid-cols-12 lg:items-center ml-6 lg:ml-0">
              <span :class="`font-bold lg:text-lg lg:col-span-${ nameWidth  }`">{{ railway.name }}</span>
              <span v-if="showTimes"
                    :class="`lg:mr-4 lg:inline ${ railway.remarks ? 'lg:text-center' : '' } lg:col-span-${ timesWidth }`">
              {{ railway.times ? railway.times : '-' }}
            </span>
              <div v-if="showRemarks" :class="`h-6 lg:inline lg:text-center lg:col-span-${ remarksWidth }`">
                <div v-if="railway.remarks.length > 0">
                  <span v-for="(remark, i) in railway.remarks" :key="i"
                        :ref="`remarks-${railway.id}`"
                        :class="`${ i > 0 ? 'hidden' : '' }`">· {{ remark }} ·</span>
                </div>
                <div v-else>-</div>
              </div>
              <span :class="`lift-state ${railway.state.toLowerCase()}`">
                  {{ translations[`MOUNTAIN_RAILWAYS.STATE_${railway.state}`] }}
                </span>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:flex w-full">
        <div class="w-full md:w-6/12 p-gap flex justify-start text-xs">
          {{ translations['LAST_UPDATED'] }} {{ railwaysLastUpdated }}
        </div>
        <div v-if="railwaysLinkUrl" class="w-full md:w-6/12 flex justify-center md:justify-end">
          <a :href="railwaysLinkUrl"
             class="p-gap button text-center mt-2 w-full mx-4 lg:hidden">{{ translations['ALL_FACILITIES'] }}</a>
          <a :href="railwaysLinkUrl" class="hidden lg:inline p-gap">{{ translations['ALL_FACILITIES'] }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MountainRailways',
  props: ['railways', 'translations', 'railwaysLinkUrl', 'railwaysLastUpdated'],
  data: () => ({
    remarksWidth: 0,
    timesWidth: 0,
    nameWidth: 0,
    showRemarks: false,
    showTimes: false,
  }),
  created() {
    this.showRemarks = this.railways.some(railway => railway.remarks);
    this.showTimes = this.railways.some(railway => railway.times);
    this.remarksWidth = this.showRemarks ? this.showTimes ? 4 : 6 : 0;
    this.timesWidth = this.showTimes ? this.showRemarks ? 2 : 5 : 0;
    this.nameWidth = 10 - this.remarksWidth - this.timesWidth;
  },
  mounted() {
    const containers = Object.entries(this.$refs).filter(ref => /^remarks/.test(ref[0]) && ref[1].length > 1);

    containers.forEach(container => {
      const delaySeconds = (container[1].length) * 4200;
      Array.from(container[1]).forEach((remark, i) => {
        setTimeout(() => {
          this.showRemark(remark);
          setTimeout(() => {
          }, 300);
          setInterval(() => {
            this.showRemark(remark);
          }, delaySeconds);
        }, i * 4200);
      });
    });
  },
  methods: {
    showRemark(remark) {
      remark.classList.remove('hidden');
      remark.classList.add('fade-in-out');
      setTimeout(() => {
        remark.classList.add('hidden');
      }, 3900);
    }
  }
};
</script>

