const imageService = {
  getTransformedSrcSet(images) {
    return (
      `${images['iPhone']} 1x, ${images['iPhone_2x']}  2x [(max-width: 375px)] | ` +
      `${images['iPhonePlus']} 1x, ${images['iPhonePlus_2x']}  2x [(max-width: 414px)] | ` +
      `${images['iPad']} 1x, ${images['iPad_2x']}  2x [(max-width: 768px)] | ` +
      `${images['iPadPro']} 1x, ${images['iPadPro_2x']}  2x [(max-width: 1024px)] | ` +
      `${images['desktop']} 1x, ${images['desktop_2x']}  2x [(min-width: 1025px)]`
    );
  }
};

export default imageService;
