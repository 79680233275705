<template>
  <div class="info-corner py-6 lg:pb-20 h-auto">
    <div class="heading-5">{{ translations['MENU.INFO_CORNER'] }}</div>
    <div class="flex flex-col md:flex-row">
      <div v-if="forecasts.length > 0" class="w-full md:w-7/12 md:mr-8" :class="{ 'md:flex md:w-full': !showSnowHeight }">
        <div class="flex items-center w-full mt-4" :class="{ 'md:w-6/12': !showSnowHeight }">
          <img class="w-1/2 md:w-3/12" :src="`/assets/images/forecast_icons/${forecasts[0].symbol}.svg`"
               aria-hidden="true"/>
          <div class="pl-4 md:pl-8">
            <div class="text-xl">{{ translations['MENU.TODAY'] }}, {{ getWeekDay(forecasts[0].date) }}, {{ formatDate(forecasts[0].date) }}</div>
            <div class="heading-4">{{ forecasts[0].maxTemp }}° / {{ forecasts[0].minTemp }}°</div>
          </div>
        </div>
        <div class="flex flex-row flex-wrap md:flex-no-wrap justify-space-around w-full mt-4" :class="{ 'md:w-6/12': !showSnowHeight }">
          <div v-for="(forecast, i) in forecasts" v-if="i > 0" class="forecast" :class="[ i > 3 ? 'hidden md:flex' : 'flex']">
            <img class="w-auto" :src="`/assets/images/forecast_icons/${forecast.symbol}.svg`"
                 aria-hidden="true"/>
            <div class="flex-col">
              <div class="font-bold text-lg">{{ getWeekDay(forecast.date) }}</div>
              <div>{{ forecast.maxTemp }}° / {{ forecast.minTemp }}°</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showSnowHeight" class="flex flex-col justify-between w-full md:w-5/12 md:ml-8">
        <div v-for="entry in snowData">
          <div v-if="entry.id === 739" class="text-xl mb-2 mt-8 md:mt-0">{{ translations['SNOW_DATA.SNOW_TOWN'] }}</div>
          <div v-else class="text-xl mb-2 mt-8 md:mt-0">{{ translations['SNOW_DATA.SNOW_MOUNTAIN'] }}</div>
          <div class="flex justify-between border-b border-t border-tertiary-gray py-2">
            <div>{{ translations['SNOW_DATA.SNOW_HEIGHT'] }}</div>
            <div class="font-bold">{{ entry.snowHeight }} cm</div>
          </div>
          <div class="flex justify-between border-bottom-tertiary-gray py-2">
            <div>{{ translations['SNOW_DATA.SNOW_HEIGHT_NEW'] }}</div>
            <div class="font-bold">{{ entry.snowHeightNew }} cm</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCorner',
  props: ['forecasts', 'snowData', 'language', 'translations'],
  data: () => ({
    i18nWeekDays: {
      'fr-CH': ['di', 'lu', 'ma', 'me', 'je', 've', 'sa'],
      'en-CH': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      'de-CH': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    }
  }),
  computed: {
    showSnowHeight() {
      return Object.keys(this.snowData).length > 0;
    }
  },
  methods: {
    formatDate(date) {
      date = new Date(date);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
    getWeekDay(date) {
      return this.i18nWeekDays[this.language][new Date(date).getDay()];
    }
  }
};
</script>
