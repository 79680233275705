import axiosInstance from './httpService';

const basePathInfoSnow = '/infosnow/info-snow';

const infoSnowService = {
  async getMountainRailwaysData(lang) {
    return axiosInstance.get(`${basePathInfoSnow}?lang=${lang}`);
  },

  formatMountainRailways(craftRailways, infoSnowRailways) {
    const railwaysIds = craftRailways.map(r => r.id);
    return infoSnowRailways.filter(railway => !!craftRailways.find(r => r.active && r.id === Number(railway.id))).sort((a, b) => railwaysIds.indexOf(Number(a.id)) < railwaysIds.indexOf(Number(b.id)) ? -1 : 1);
  }
};

export default infoSnowService;
